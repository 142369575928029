export const required = value => (value ? undefined : 'Required');

export const isLength = length => value => {
  const displayRequiredError = required(value);

  if (displayRequiredError) {
    return displayRequiredError;
  }

  const isLengthValid = value.length >= length;

  return isLengthValid ? undefined : `Should be at least ${length} characters`;
};

export const isEmailValid = value =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value,
  )
    ? undefined
    : 'Please enter a valid email';

export const validatePassword = value =>
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$.!%*#?&]/.test(value)
    ? undefined
    : 'Password must include one lowercase character, one uppercase character, a number, and a special character.';

export const isValidUrl = url => {
  if (!url) {
    return undefined;
  }

  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return undefined;
  } catch (e) {
    return 'Please enter a valid URL';
  }
};
