import { Button } from '@actovos-consulting-group/ui-core';
import { ImTwitter } from '@react-icons/all-files/im/ImTwitter';
import firebase from 'firebase/app';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.colors.twitterBlue};
    border-color: ${theme.colors.twitterBlue};

    &:hover {
      background-color: ${theme.colors.twitterBlue};
      border-color: ${theme.colors.twitterBlue};
    }
  `}
`;

const TwitterButton = ({ children, onSuccess }) => {
  const onTwitterSignIn = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(res => {
        if (onSuccess) {
          onSuccess(res);
        }
      });
  };

  return (
    <StyledButton onClick={onTwitterSignIn} size="xl" leftIcon={ImTwitter}>
      {children}
    </StyledButton>
  );
};

export default TwitterButton;
