import {
  Alert,
  Button,
  Field,
  Input,
  Toaster,
} from '@actovos-consulting-group/ui-core';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { Field as RField, Form } from 'react-final-form';
import { useAuthContext } from '../../../../firebase/context';
import { required } from '../../../../utils/validations';

const EditProfileForm = () => {
  const { user, actions } = useAuthContext();

  const onSubmit = async data => {
    await actions.updateUser(data);
    mixpanel.track('Update Profile');
    Toaster.alert({ value: 'Profile Updated!', variant: 'success' });
  };

  return (
    <Form onSubmit={onSubmit} initialValues={user}>
      {({
        handleSubmit,
        hasValidationErrors,
        hasSubmitErrors,
        modifiedSinceLastSubmit,
        submitting,
        submitError,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <RField name="name" validate={required}>
              {({ input, meta }) => (
                <Field mb={meta.touched && meta.error ? 40 : 20}>
                  <Input
                    label="Name"
                    size="lg"
                    error={meta.error && meta.touched && meta.error}
                    {...input}
                  />
                </Field>
              )}
            </RField>
            <RField name="bio" validate={required}>
              {({ input, meta }) => (
                <Field mb={meta.touched && meta.error ? 40 : 20}>
                  <Input
                    label="Bio"
                    type="textarea"
                    size="lg"
                    error={meta.error && meta.touched && meta.error}
                    {...input}
                  />
                </Field>
              )}
            </RField>
            {submitError && <Alert variant="danger">{submitError}</Alert>}

            <Button
              disabled={
                hasValidationErrors ||
                (!modifiedSinceLastSubmit && hasSubmitErrors)
              }
              loading={submitting}
              type="submit"
              fullWidth
              size="lg"
            >
              Save
            </Button>
          </form>
        );
      }}
    </Form>
  );
};

export default EditProfileForm;
