import {
  Block,
  Container,
  Flex,
  Text,
} from '@actovos-consulting-group/ui-core';
import React from 'react';
import { useAuthContext } from '../../../firebase/context';
import DisableSSR from '../../disable-ssr';
import SEO from '../../seo';
import EditProfileForm from './components/edit-profile-form';

const AvatarUpload = React.lazy(() => import('./components/avatar-upload'));

const EditProfilePage = () => {
  const { user, actions } = useAuthContext();

  return (
    <Container maxWidth={800}>
      <SEO
        title="Edit Profile"
        description="Edit your profile details and upload a photo."
      />
      <Text as="h2" textAlign="center">
        Edit Profile
      </Text>
      <Flex mt={100} flexDirection={{ _: 'column', xs: 'row' }}>
        <Block mr={{ _: 0, xs: 50 }}>
          <Text textAlign="center" mb={2} fontSize="0.8rem" fontWeight={600}>
            Profile Image
          </Text>
          <DisableSSR>
            <AvatarUpload
              isCircle
              fileName="avatar.jpg"
              storagePath={`users/${user?.id}`}
              onDelete={() => actions.updateUser({ photoURL: null })}
              onUpload={file => actions.updateUser({ photoURL: file })}
            />
          </DisableSSR>
        </Block>
        <Block my={{ _: 30, xs: 0 }} flex={1}>
          <EditProfileForm />
        </Block>
      </Flex>
    </Container>
  );
};

export default EditProfilePage;
