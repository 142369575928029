import {
  Block,
  Container,
  Flex,
  Text,
} from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import RegisterForm from '../../register-form';

const RegisterPage = () => {
  return (
    <Container maxWidth={400} style={{ textAlign: 'center' }}>
      <Block my={50}>
        <StaticImage
          src="../../../images/cheers.png"
          width={300}
          alt="Cheers"
        />
        <RegisterForm
          onSuccess={() => {
            mixpanel.track('Register');
          }}
        />
        <Flex mx={30} justifyContent="space-around" alignItems="center" mt={40}>
          <Text fontSize={16} mr={20}>
            Already have an Account?
          </Text>
          <Link to="/app/login">Login</Link>
        </Flex>
      </Block>
    </Container>
  );
};

export default RegisterPage;
