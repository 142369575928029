import {
  Block,
  Button,
  Container,
  Flex,
  Text,
} from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import styled from 'styled-components';
import LoginForm from '../../login-form';

const AccountContainer = styled(Flex)`
  border: 2px solid ${p => p.theme.colors.primary};
`;

const LoginPage = () => {
  return (
    <Container maxWidth={400} style={{ textAlign: 'center' }}>
      <Block my={50}>
        <AccountContainer
          justifyContent="space-between"
          alignItems="center"
          border="1px solid black"
          p={20}
          borderRadius={10}
          mb={40}
        >
          <Text color="primary" fontSize={20} fontWeight={500} mr={20}>
            Need an Account?
          </Text>
          <Button as={Link} to="/app/register" size="xl">
            Sign Up
          </Button>
        </AccountContainer>
        <LoginForm
          onSuccess={() => {
            mixpanel.track('Login');
          }}
        />
      </Block>
    </Container>
  );
};

export default LoginPage;
