import {
  Alert,
  Button,
  Field,
  Flex,
  Input,
  Toaster,
} from '@actovos-consulting-group/ui-core';
import firebase from 'firebase/app';
import 'firebase/auth';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { Field as RField, Form } from 'react-final-form';
import { isEmailValid, required } from '../utils/validations';
import OrDivider from './or-divider';
import TwitterButton from './twitter-button';

export default ({ onSuccess }) => {
  const onSubmit = async ({ email, password }) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        if (onSuccess) {
          mixpanel.track('New Sign Up');
          onSuccess(user);
        }
      })
      .catch(() => {
        Toaster.alert({ value: 'An error has occured!', variant: 'danger' });
      });
  };

  return (
    <>
      <Flex py={4} alignItems="center" justifyContent="center">
        <TwitterButton onSuccess={onSuccess}>Signup with Twitter</TwitterButton>
      </Flex>
      <OrDivider />
      <Form
        onSubmit={onSubmit}
        validate={values => {
          const errors = {};
          if (values.confirm !== values.password) {
            errors.confirm = 'Must match';
          }
          return errors;
        }}
      >
        {({
          handleSubmit,
          hasValidationErrors,
          hasSubmitErrors,
          modifiedSinceLastSubmit,
          submitting,
          submitError,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <RField name="email" validate={isEmailValid}>
                {({ input, meta }) => (
                  <Field mb={meta.touched && meta.error ? 40 : 20}>
                    <Input
                      label="Email"
                      size="lg"
                      error={meta.error && meta.touched && meta.error}
                      {...input}
                    />
                  </Field>
                )}
              </RField>
              <RField name="password" validate={required}>
                {({ input, meta }) => (
                  <Field mb={meta.touched && meta.error ? 40 : 20}>
                    <Input
                      label="Password"
                      type="password"
                      size="lg"
                      error={meta.error && meta.touched && meta.error}
                      {...input}
                    />
                  </Field>
                )}
              </RField>
              <RField name="confirm" validate={required}>
                {({ input, meta }) => (
                  <Field mb={meta.touched && meta.error ? 40 : 20}>
                    <Input
                      label="Confirm Password"
                      type="password"
                      size="lg"
                      error={meta.error && meta.touched && meta.error}
                      {...input}
                    />
                  </Field>
                )}
              </RField>
              {submitError && <Alert variant="danger">{submitError}</Alert>}

              <Button
                disabled={
                  hasValidationErrors ||
                  (!modifiedSinceLastSubmit && hasSubmitErrors)
                }
                loading={submitting}
                type="submit"
                fullWidth
                size="lg"
              >
                Register
              </Button>
            </form>
          );
        }}
      </Form>
    </>
  );
};
