import { navigate } from 'gatsby';
import React from 'react';
import { useAuthContext } from '../firebase/context';

const Route = ({ component: Component, onlyPublic, onlyPrivate, ...rest }) => {
  const { user } = useAuthContext();

  if (onlyPublic && !!user?.id) {
    navigate('/');
    return null;
  }

  // eslint-disable-next-line no-restricted-globals
  if (onlyPrivate && !user?.id && location.pathname !== `/app/login`) {
    navigate('/app/login');
    return null;
  }

  return <Component {...rest} />;
};

export default Route;
