import { Router } from '@reach/router';
import React from 'react';
import Layout from '../../components/layout';
import Route from '../../components/route';
import EditProfilePage from '../../components/scenes/edit-profile';
import LoginPage from '../../components/scenes/login';
import RegisterPage from '../../components/scenes/register';

const App = () => (
  <Layout>
    <Router basepath="/app">
      <Route component={LoginPage} path="/login" onlyPublic />
      <Route component={RegisterPage} path="/register" onlyPublic />
      <Route component={EditProfilePage} path="/edit-profile" onlyPrivate />
    </Router>
  </Layout>
);

export default App;
