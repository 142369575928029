import {
  Alert,
  Button,
  Field,
  Flex,
  Input,
  Text,
  Toaster,
} from '@actovos-consulting-group/ui-core';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import React from 'react';
import { Field as RField, Form } from 'react-final-form';
import { isEmailValid, required } from '../utils/validations';
import TwitterButton from './twitter-button';

export default ({ onSuccess }) => {
  const onSubmit = ({ email, password }) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        if (onSuccess) {
          onSuccess(user);
        } else {
          navigate('/');
        }
      })
      .catch(err => {
        if (err.code === 'auth/wrong-password') {
          Toaster.alert({ value: 'Invalid Password', variant: 'danger' });
        } else {
          Toaster.alert({ value: 'An error has occured!', variant: 'danger' });
        }
      });
  };

  return (
    <>
      <Flex py={4} alignItems="center" justifyContent="center">
        <TwitterButton onSuccess={onSuccess}>Login with Twitter</TwitterButton>
      </Flex>
      <Text textAlign="center" mb={4}>
        Or
      </Text>
      <Form onSubmit={onSubmit}>
        {({
          handleSubmit,
          hasValidationErrors,
          hasSubmitErrors,
          modifiedSinceLastSubmit,
          submitting,
          submitError,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <RField name="email" validate={isEmailValid}>
                {({ input, meta }) => (
                  <Field mb={meta.touched && meta.error ? 40 : 20}>
                    <Input
                      label="Email"
                      size="lg"
                      error={meta.error && meta.touched && meta.error}
                      {...input}
                    />
                  </Field>
                )}
              </RField>
              <RField name="password" validate={required}>
                {({ input, meta }) => (
                  <Field mb={meta.touched && meta.error ? 40 : 20}>
                    <Input
                      label="Password"
                      type="password"
                      size="lg"
                      error={meta.error && meta.touched && meta.error}
                      {...input}
                    />
                  </Field>
                )}
              </RField>
              {submitError && <Alert variant="danger">{submitError}</Alert>}

              <Button
                disabled={
                  hasValidationErrors ||
                  (!modifiedSinceLastSubmit && hasSubmitErrors)
                }
                loading={submitting}
                type="submit"
                fullWidth
                size="lg"
              >
                Login
              </Button>
            </form>
          );
        }}
      </Form>
    </>
  );
};
