import { Block, Text } from '@actovos-consulting-group/ui-core';
import React from 'react';

const OrDivider = () => {
  return (
    <Block position="relative" height={50}>
      <Block
        position="absolute"
        width="100%"
        height={1}
        bg="mutedGrey"
        top={12}
        left={0}
        zIndex={1}
      />
      <Text
        zIndex={2}
        position="relative"
        bg="white"
        fontWeight={300}
        fontSize={20}
        width={40}
        textAlign="center"
        m="0 auto"
      >
        Or
      </Text>
    </Block>
  );
};

export default OrDivider;
